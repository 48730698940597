import App from './App.vue'
import {createMemoryHistory, createRouter} from 'vue-router'
import {createPinia, PiniaVuePlugin} from 'pinia'
import {useStormsDatastore} from "@/store/Storms";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap';
import {library} from "@fortawesome/fontawesome-svg-core";
import Keycloak from "keycloak-js";
import Axios from "axios";
import {useAuthenticationDatastore} from "@/store/Authentication";
import draggable from "vuedraggable";

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

require("./assets/style/custom.scss");


import {
    faPlay,
    faStop,
    faTrash,
    faFile,
    faPen,
    faArrowUp,
    faArrowDown,
    faBars,
    faHome,
    faFloppyDisk,
    faQuestion,
    faDownload,
    faBarsProgress
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {createApp} from "vue";
// import endpointsAndConstants from "@/EndpointsAndConstants";

library.add(
    faPlay,
    faStop,
    faTrash,
    faFile,
    faPen,
    faArrowUp,
    faArrowDown,
    faBars,
    faHome,
    faFloppyDisk,
    faQuestion,
    faDownload,
    faBarsProgress
);

const pinia = createPinia();

let keycloak = new Keycloak({
    url: 'https://keycloak-hub.public.lqd.dk/',
    realm: 'StormRobotRealm',
    clientId: 'stormrobot-frontend',
    onLoad: 'login-required',
    publicClient: true,
});

keycloak
    .init({onLoad: 'login-required'})
    .then((auth) => {
        if (auth === true) {

            localStorage.setItem("vue-token", keycloak.token);
            localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

            // TODO Enable baseURL when developing
            // Axios.defaults.baseURL = "http://localhost:10000";
            // Axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT;
            // Axios.defaults.maxRedirects = 100;


            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


            Axios.defaults.headers.authorization = "Bearer " + keycloak.token;

            const application = createApp(App);
            application.use(PiniaVuePlugin).use(pinia);

            const router = createRouter({
                history: createMemoryHistory(),
                routes: [
                    {
                        path: '/',
                        name: 'Index',
                        component: () => import('./components/StormView.vue')
                    },
                    {
                        path: '/storm/:id',
                        name: 'Details',
                        component: () => import('./components/StormDetails.vue'),
                        props: true
                    },
                    {
                        path: '/createstorm',
                        name: 'CreateStorm',
                        component: () => import('./components/StormDetails.vue'),
                        props: true
                    },
                    {
                        path: '/sessions',
                        name: 'Sessions',
                        component: () => import('./components/SessionsView.vue'),
                        props: true
                    },
                    {
                        path: '/session/:id',
                        name: 'SessionDetails',
                        component: () => import('./components/SessionDetails.vue'),
                        props: true
                    }
                ]
            })
            // router.beforeEach((to) => {
            //     const store = useStormStore();
            // })
            // pinia.use(({store}) => {
            //     store.router = markRaw(router);
            // })

            application.use(router);
            application.use(ToastPlugin);
            application.component("font-awesome-icon", FontAwesomeIcon);
            application.component("Keycloak_Component", require("keycloak-js"));
            application.component("draggable_Component", draggable);
            application.mount("#app");
            application.config.globalProperties.$keycloak = keycloak;

            let authStore = useAuthenticationDatastore();
            keycloak.loadUserProfile().then(function (profile) {
                authStore.$state.user = profile;
                useStormsDatastore().fetchStorms();
                useStormsDatastore().fetchHistoryOfSessions();
            }).catch(error => {
                console.error("Could not load keycloak user: ");
                console.error(error);
            })
        }
    })

keycloak.onTokenExpired = function () {
    keycloak
        .updateToken(0)
        .then((refreshed) => {
            if (refreshed) {
                console.log("Token refreshed", refreshed);
                localStorage.setItem("vue-token", keycloak.token);
                localStorage.setItem("vue-refresh-token", keycloak.refreshToken);
                Axios.defaults.headers.authorization = "Bearer " + keycloak.token;
            } else {
                console.warn(
                    "Token not refreshed, valid for ",
                    Math.round(
                        keycloak.tokenParsed.exp +
                        keycloak.timeSkew -
                        new Date().getTime() / 1000
                    ) + " seconds"
                );
            }
        })
        .catch((err) => {
            console.error("Failed to refresh token", err);
        });
};

