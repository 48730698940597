export const constants = Object.freeze({
    maxFieldLength: 50,
    runningStormsPollInMillis: 5000,
    endpoints: {
        keycloak: "",
        FRONTEND_CONFIG: "/unsecure/config",
        allStorms: "frontend/stormProject",
        FRONTEND_BASE: "/frontend",
        STORMS: "/stormProject",
        RUNNINGSTORMS: "/runningstorms",
        SESSIONS: "/sessions",
        SESSION_HISTORY: "/sessionHistory/"

    }
})

export default Object.freeze(constants);
