import { defineStore } from 'pinia'
import Axios from "axios";
import endpointsAndConstants from "@/EndpointsAndConstants";
import {useToast} from 'vue-toast-notification';
import {download, generateCsv, mkConfig} from "export-to-csv";


export const useStormsDatastore = defineStore({
    // id is required so that Pinia can connect the store to the devtools
    id: 'StormsDatastore',
    state: ()=>(
        {
            storms: [],
            runningStorms: [],
            sessions: [],
            currentSessionEvents: [],
            queuedCalls: 0,
            sourceStorm: {}
        }
    ),
    getters: {
        getRunningStorms: (state) => {
            return state.runningStorms;
        },
        getAllStorms: (state) => {
            return state.storms;
        },
        getCurrentSessionEvents: (state) => {
            return state.currentSessionEvents;
        },
        getQueuedCalls: (state) => {
            return state.queuedCalls;
        },
        getSessions: (state) => {
            return state.sessions;
        },
        getSourceStorm: (state) => {
            return state.sourceStorm;
        }
    },
    actions:{
        async fetchStorms() {
            return Axios.get(endpointsAndConstants.endpoints.FRONTEND_BASE + endpointsAndConstants.endpoints.STORMS)
                .then(response => {
                this.storms = response.data;
            })
                .catch(__ => {
                    console.log(__);
                    useToast().error("Could not fetch storms.")
                })
        },
        async fetchRunningStorms() {
            return Axios.get(endpointsAndConstants.endpoints.FRONTEND_BASE + endpointsAndConstants.endpoints.STORMS + endpointsAndConstants.endpoints.RUNNINGSTORMS)
                .then(response => {
                    this.runningStorms = [];
                    this.queuedCalls = 0;
                    // console.log(response.data.length)
                    if(response.data.length > 0){
                        response.data.forEach((stormWithSessionId) => {
                            this.runningStorms.push({stormID: stormWithSessionId.id, sessionID: stormWithSessionId.sessionId});
                            this.queuedCalls += stormWithSessionId.numberOfCall;
                        });
                    }
                })
                .catch(__ => {
                    useToast().error("Fetch Running Storms failed.");
                    console.error(__)
                })
        },
        async fetchHistoryOfSessions(){
            return Axios.get(endpointsAndConstants.endpoints.FRONTEND_BASE + endpointsAndConstants.endpoints.SESSIONS)
                .then(response => {
                    this.sessions = [];
                    console.log(response.data);
                    if(response.data.length > 0){
                        response.data.forEach((session) => {
                            this.sessions.push(session)
                        });
                    }
                })
        },
        async fetchSessionHistory(id){
            this.currentSessionEvents = [];
            console.log(id);
            Axios.get(endpointsAndConstants.endpoints.FRONTEND_BASE + endpointsAndConstants.endpoints.SESSION_HISTORY + id).then(response => {
                console.log(response.data);
                if(response.data.length > 0){
                    response.data.forEach((event) => {
                        this.currentSessionEvents.push(event)
                    });
                }
            }).catch(error => {
                console.error(error);
            })
        },
        async runStorm(storm){
            Axios.post(endpointsAndConstants.endpoints.FRONTEND_BASE + "/function/start/" + storm.name)
                .catch(__ => {
                    useToast().error(__.response.data);
                    console.log(__);
                });
            useToast().success(storm.name + " start requested.");
        },
        stopStorm(sessionID){
            return Axios.post(endpointsAndConstants.endpoints.FRONTEND_BASE + "/function/forcedStop/" + sessionID)
                .then(() => {
                    useToast().success("Storm stopped.");
                    this.fetchRunningStorms();
                }).catch(__ => {
                    useToast().error("Stop storm failed.");
                    console.log(__);
                })
        },
        stopAllStorms(){
            return Axios.post(endpointsAndConstants.endpoints.FRONTEND_BASE + "/function/forcedStopAll").then(() => {
                useToast().success("All storms forcefully stopped.");
                return true;
            }).catch(__ => {
                console.log(__);
                useToast().error("Force stop all failed.");
                return false;
            })
        },
        async addStorm(storm){
            return Axios.post(endpointsAndConstants.endpoints.FRONTEND_BASE + endpointsAndConstants.endpoints.STORMS, storm).then(() => {
                return true;
            }).then(() => {
                useToast().success(storm.name + " was added.");
                this.fetchStorms();
            }).catch((error) => {
                useToast().error("Could not add " + storm.name + ", " + JSON.stringify(error.response.data.message), {duration:30000, dismissible: true});
                console.error(error);
                return false
            })
        },
        async saveStorm(id, storm){
            return Axios.put(endpointsAndConstants.endpoints.FRONTEND_BASE + endpointsAndConstants.endpoints.STORMS, storm)
                .then(() => {
                    useToast().success(storm.name + " was saved.");
                    this.fetchStorms()
                })
                .catch((error) => {
                    console.log(error);
                    useToast().error(storm.name + " could not save " + JSON.stringify(error.response.data.message), {duration:30000, dismissible: true});
                    console.error(error);
                })
        },
        deleteStorm(id){
            Axios.delete(endpointsAndConstants.endpoints.FRONTEND_BASE + endpointsAndConstants.endpoints.STORMS + "/" + id)
                .then(() => {
                    this.fetchStorms();
                    this.fetchRunningStorms();
                    useToast().success("Storm was deleted.");
                    return true;
                }).catch(error => {
                    useToast().error("Storm could not delete.");
                    console.log(error.data);
                    return false;
            })
        },
        downloadCSV(){
            const csvConfig = mkConfig({
                useKeysAsHeaders: true,
                filename: this.currentSessionEvents.at(0).sessionId,
                fieldSeperator: ",",
                quoteStrings: false,
                quoteCharacter: "'"
            });
            console.log(this.currentSessionEvents)
            const csv = generateCsv(csvConfig)(this.currentSessionEvents);
            download(csvConfig)(csv);
        },
        downloadCSVWithID(session){
            let sessionArray = [];
            if(session === null) {
                session = this.sessions.find(item => (item.sessionID === this.currentSessionEvents.at(0).sessionId))
            }
            let id = session.sessionID;
            
            Axios.get(endpointsAndConstants.endpoints.FRONTEND_BASE + endpointsAndConstants.endpoints.SESSION_HISTORY + id).then(response => {
                console.log(response.data);
                if(response.data.length > 0){
                    response.data.forEach((event) => {
                        sessionArray.push(event)
                    });
                    const csvConfig = mkConfig({
                        useKeysAsHeaders: true,
                        filename: session.stormProjectName + "_" + id,
                        fieldSeperator: ",",
                        quoteStrings: false,
                        quoteCharacter: "'"
                    });
                    console.log(sessionArray)
                    const csv = generateCsv(csvConfig)(sessionArray);
                    download(csvConfig)(csv);
                }
            }).catch(error => {
                console.error(error);
            })
        }
    }
})