import { defineStore } from 'pinia'

export const useAuthenticationDatastore = defineStore({
    id: 'AuthenticationDatastore',
    state: ()=>({
            user: {
                username: ""
            }

    }),
    getters: {
        getLoggedInUser: (state) =>  {
            return state.user
        }
    },
    actions:{
        setUser(user){
            console.log(user);
            this.user = user;
        }
    }

})