<template>

  <div  class="nav pe-1 ps-3 sticky-top justify-content-between bg-light border-bottom">
    <div class="justify-content-start align-items-center d-flex">
      StormRobot {{this.queued}}
    </div>
    <div class=" d-flex align-content-end" >
      <router-link to="/"><button class="btn btn-light border-0">Storms </button></router-link>
      <router-link to="/sessions"><button class="btn btn-light border-0">Sessions</button></router-link>
      <!--      <button class="align-self-start" @click="goHome()"> <font-awesome-icon icon="home"/> </button>-->
      <div class="dropdown">
        <button class="dropdown-toggle btn btn-light"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownMenuButton1" >
          {{authStore.getLoggedInUser.username}}
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li><button class="dropdown-item" @click="manageAccount" >Manage Account</button></li>
          <li><button class="dropdown-item" @click="logout" >Logout</button></li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import {useAuthenticationDatastore} from "@/store/Authentication";
import {useStormsDatastore} from "@/store/Storms";

export default {
  name: "NavigationBar",
  methods: {
    logout() {
      this.$keycloak.logout();
    },
    manageAccount() {
      this.$keycloak.accountManagement();
    }
  },
  computed: {
    queued() {
      if(this.stormsStore.queuedCalls > 0){
        return this.stormsStore.queuedCalls;
      } else {
        return null;
      }
    }
  },
  setup() {
    const authStore = useAuthenticationDatastore();
    const stormsStore = useStormsDatastore();
    return {authStore, stormsStore};
  }
}
</script>

<style scoped>

</style>